import axios from "axios";
import styles from "./styles.json";
import poi from './assets/images/poi-custom.png'
import grave from './assets/images/grave.png'

let locations = [];
let isAdding = false;
let isClicked = false;

let nav = document.getElementById('nav')

let new_polygon;
let infobox;

let map;
const server = "https://hanapdpuntod.xyz/server";
let panel = document.getElementById('panel')
let infoPanel = document.getElementById('infoPanel')

let showGraves = true

// #region Panel
function showPanel(data) {
  panel.classList.remove('hide')
  panel.classList.add('show')
  infoPanel.classList.remove('hide')
  infoPanel.classList.add('show')
  let male = 0, female = 0, total = 0;
  total = data.graves.length

  let template = `<div class="p-2 d-flex flex-column gap-1">`;
  // #region Graves
  if (data.graves.length > 0) {
    data.graves.forEach(g => {
      let name = (g.first_name != '') ? (g.first_name + (g.midlle_name != '' ? ` ${g.middle_name} ` : ` `) + g.last_name) : 'No name'
      if (g.gender == 'Male') {
        male++
      } else {
        female++
      }
      template += `
        <div class="record d-flex align-items-center justify-content-between bg-white shadow-lg rounded-2 gap-2 border boder-1 border-dark p-2">
          <img src="${grave}" style="max-width: 30px"/>
          <div class="d-flex flex-column flex-fill">
            <b id="name" class="fs-5 text-truncate">${name}</b>
            <small class="text-muted"><i class="bi-calendar"></i> Born on: <span id="born_on">${g.born_on}</span></small>
            <small class="text-muted"><i class="bi-calendar"></i> Died on: <span id="died_on">${g.died_on}</span></small>
          </div>
        </div>
      `
    })
  } else {
    template += ` 
      <div class="bg-white p-3">
        <h1 class="fs-4 m-0">No record.</h1>
        <small class="m-0">It looks like there is nothing to show here.</small>
      </div>
    `
  }
  template += `</div>`
  // #endregion
  panel.innerHTML = template

  let infoPanelTemplate = `
    <div class="card rounded-0 shadow-lg m-0">
      <div class="card-header">
        <h1 class="card-title fs-4 fw-bolder m-0 text-black">${(data.name) ? data.name : 'Unnamed'}</h1>
        <small class="d-block border-bottom py-2 text-dark"><i class='bi-geo-alt-fill'></i> ${(data.address) ? data.address : 'No address avialable.'}</small>
      </div>
      <div class="card-body">
        <p class="m-0">${(data.description) ? data.description : 'No description available.'}</p>
      </div>
    </div>
    <form class="p-2">
      <input type="search" class="form-control form-control-lg" autocomplete="off" id="searchGrave" placeholder="Search inside"/>
    </form>
    <div class="d-flex flex-column justify-content-start text-white p-3 gap-2">
      <div id="filterMale" style="cursor: pointer" class="d-flex align-items-center justify-content-between bg-primary bg-opacity-75 bg-gradient border border-1 border-primary rounded-2 py-2">
        <div class="col-4 d-flex flex-column align-items-center justify-content-center text-center">
          <i class='bi-gender-male fs-1'></i>
          <small style="font-size: 10px" class="text-uppercase">Male</small>
        </div>
        <div class="col-8 d-flex flex-column flex-wrap text-start">
          <h1 class="m-0 fs-1 text-white">${male}</h1>
        </div>
      </div>
      <div id="filterFemale" style="cursor: pointer" class="d-flex align-items-center justify-content-between bg-success bg-opacity-75 bg-gradient border border-1 border-success rounded-2 py-2">
        <div class="col-4 d-flex flex-column align-items-center justify-content-center text-center">
          <i class='bi-gender-female fs-1'></i>
          <small style="font-size: 10px" class="text-uppercase">Female</small>
        </div>
        <div class="col-8 d-flex flex-column flex-wrap text-start">
          <h1 class="m-0 fs-1 text-white">${female}</h1>
        </div>
      </div>
      <div id="filterAll" style="cursor: pointer" class="d-flex align-items-center justify-content-between bg-warning bg-opacity-75 bg-gradient border border-1 border-warning rounded-2 py-2">
        <div class="col-4 d-flex flex-column align-items-center justify-content-center text-center">
          <i class='bi-people-fill fs-1'></i>
          <small style="font-size: 10px" class="text-uppercase">Total</small>
        </div>
        <div class="col-8 d-flex flex-column flex-wrap text-start">
          <h1 class="m-0 fs-1 text-white">${total}</h1>
        </div>
      </div>
    </div>
  `
  infoPanel.innerHTML = infoPanelTemplate

  InvokeFunctions(data.graves)
}

function hidePanel() {
  panel.classList.remove('show')
  panel.classList.add('hide')
  panel.innerHTML = ''
  infoPanel.classList.remove('show')
  infoPanel.classList.add('hide')
  infoPanel.innerHTML = ''
}

function editGrave(data, element) {
  swal.fire({
    icon: 'question',
    title: 'Edit Information',
    iconHtml: "<i class='bi-pencil-square fs-1'></i>",
    html: `<form id="form" method="post" name="save" class="text-start">
            <label for="first_name">First Name</label>
            <input value="${data.first_name}" id="first_name" name="first_name" class="form-control my-2"/>
            <label for="middle_name">Middle Name</label>
            <input value="${data.middle_name}" id="middle_name" name="middle_name" class="form-control my-2"/>
            <label for="last_name">Last Name</label>
            <input value="${data.last_name}" id="last_name" name="last_name" class="form-control my-2"/>
            <label for="gender">Gender</label>
            <select id="gender" name="gender" class="form-control my-2">
              <option value="">Select</option>
              <option value="Male" ${ data.gender=='Male' && 'selected'}>Male</option>
              <option value="Female" ${data.gender=='Female' && 'selected'}>Female</option>
            </select>
            <div class="row">
              <div class="col-6">
                <label for="born_on">Born on</label>
                <input value="${data.born_on}" id="born_on" name="born_on" type="date" class="form-control my-2" onchange="died_on.setAttribute('min', this.value); died_on.removeAttribute('disabled');"/>
              </div>
              <div class="col-6">
                <label for="died_on">Died on</label>
                <input value="${data.died_on}" id="died_on" name="died_on" type="date" class="form-control my-2" min="${data.born_on}"/>
              </div>
            </div>
          </form>`,
    width: 400,
    allowOutsideClick: false,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "<i class='bi-check-lg'></i> Save",
    cancelButtonText: "<i class='bi-x-lg'></i> Cancel",
    customClass: {
      title: 'fs-4',
      cancelButton: "btn btn-danger bg-gradient",
      confirmButton: "btn btn-success bg-gradient",
    },
  })
    .then((response) => {
      var d = {
        id: data.id,
        first_name: save.first_name.value,
        middle_name: save.middle_name.value,
        last_name: save.last_name.value,
        gender: save.gender.value,
        born_on: save.born_on.value,
        died_on: save.died_on.value,
      }
      if (response.isConfirmed) {
        showLoading('Updating record, please wait.')
        axios
        .post(`${server}/grave-edit.php`, d)
        .then(function (response) {
          showMessage("success", response.data.message);
          loadCemetery(map);
          updateGraveElement(d, element)
        })
        .catch(function (error) {
          console.log(error);
          showMessage("error", "Failed to updated record.");
        });
    } else {
      showMessage("warning", "Action was cancelled.");
    }
  });
}

function updateGraveElement(data, element) {
  let name = (data.first_name != '')?(data.first_name + (data.midlle_name!=''?` ${data.middle_name} `:` `) + data.last_name):'No name'
  element.querySelector('#name').innerHTML = name
  element.querySelector('#born_on').innerHTML = data.born_on != '' ? data.born_on : "0000-00-00"
  element.querySelector('#died_on').innerHTML = data.died_on != '' ? data.born_on : "0000-00-00"
  element.querySelector('#edit').setAttribute('data', JSON.stringify(data))
  element.querySelector('#delete').setAttribute('data', JSON.stringify(data))
}

function deleteGrave(data, element) {
  swal.fire({
    icon: 'question',
    width: 350,
    text: 'are you sure you want to delete this record?',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "<i class='bi-trash'></i> Delete",
    cancelButtonText: "<i class='bi-x-lg'></i> Cancel",
    customClass: {
      title: 'fs-4',
      cancelButton: "btn btn-success bg-gradient",
      confirmButton: "btn btn-danger bg-gradient",
    },
  }).then(response => {
    if (response.isConfirmed) {
      showLoading('Deleting record, please wait.')
      axios
        .post(`${server}/grave-delete.php`, {
          id: data.id,
        })
        .then(function (response) {
          showMessage("success", response.data.message);
          loadCemetery(map);
          element.remove()
        })
        .catch(function (error) {
          console.log(error);
          showMessage("error", "Failed to delete record.");
        });
    } else {
      showMessage('warning', 'Action was cancelled.')
    }
  })
}

function deleteCemetery(data) {
  swal.fire({
    icon: 'question',
    width: 350,
    text: 'are you sure you want to delete this cemetery?',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "<i class='bi-trash'></i> Delete",
    cancelButtonText: "<i class='bi-x-lg'></i> Cancel",
    customClass: {
      title: 'fs-4',
      cancelButton: "btn btn-success bg-gradient",
      confirmButton: "btn btn-danger bg-gradient",
    },
  }).then(response => {
    if (response.isConfirmed) {
      showLoading('Deleting record, please wait.')
      axios
        .post(`${server}/cemetery-delete.php`, {
          id: data.id
        })
        .then(function (response) {
          hidePanel()
          showMessage("success", response.data.message);
          loadCemetery(map);
        })
        .catch(function (error) {
          console.log(error);
          showMessage("error", "Failed to delete record.");
        });
    } else {
      showMessage('warning', 'Action was cancelled.')
    }
  })
}

function filterGender(data, gender) {
  let filteredData
  (gender != '') ? filteredData = data.filter(d => d.gender.includes(gender)) : filteredData = data
  let template = `<div class="p-2 d-flex flex-column gap-1">`;
  filteredData.forEach(g => {
    let name = (g.first_name != '') ? (g.first_name + (g.midlle_name != '' ? ` ${g.middle_name} ` : ` `) + g.last_name) : 'No name'
    template += `
      <div class="record d-flex align-items-center justify-content-between bg-white shadow-lg rounded-2 gap-2 border boder-1 border-dark p-2">
        <img src="${grave}" style="max-width: 30px"/>
        <div class="d-flex flex-column flex-fill">
          <b id="name" class="fs-5 text-truncate">${name}</b>
          <small class="text-muted"><i class="bi-calendar"></i> Born on: <span id="born_on">${g.born_on}</span></small>
          <small class="text-muted"><i class="bi-calendar"></i> Died on: <span id="died_on">${g.died_on}</span></small>
        </div>
      </div>
    `
  })
  template += `</div>`
  panel.innerHTML = template
}

function filterSearch(data, keyword) {
  let filteredData
  (keyword != '') ? filteredData = data.filter(d => (d.first_name.toLowerCase().includes(keyword) || d.last_name.toLowerCase().includes(keyword))) : filteredData = data
  let template = `<div class="p-2 d-flex flex-column gap-1">`;
  if (filteredData.length == 0) {
    template += `
      <div class="bg-white p-3">No result for <b class="fw-bolder">${keyword}</b>, try another keyword.</div>
    `
  } else {
    filteredData.forEach(g => {
      let name = (g.first_name != '') ? (g.first_name + (g.midlle_name != '' ? ` ${g.middle_name} ` : ` `) + g.last_name) : 'No name'
      template += `
        <div class="record d-flex align-items-center justify-content-between bg-white shadow-lg rounded-2 gap-2 border boder-1 border-dark p-2">
          <img src="${grave}" style="max-width: 30px"/>
          <div class="d-flex flex-column flex-fill">
            <b id="name" class="fs-5 text-truncate">${name}</b>
            <small class="text-muted"><i class="bi-calendar"></i> Born on: <span id="born_on">${g.born_on}</span></small>
            <small class="text-muted"><i class="bi-calendar"></i> Died on: <span id="died_on">${g.died_on}</span></small>
          </div>
        </div>
      `
    })
  }
  template += `</div>`
  panel.innerHTML = template
}

function InvokeFunctions(data) {

  let filterMale = document.getElementById('filterMale');
  filterMale.addEventListener('click', (e) => {
    filterGender(data, 'Male');
  })

  let filterFemale = document.getElementById('filterFemale');
  filterFemale.addEventListener('click', (e) => {
    filterGender(data, 'Female');
  })

  let filterAll = document.getElementById('filterAll');
  filterAll.addEventListener('click', (e) => {
    filterGender(data, '');
  })

  let searchGrave = document.getElementById('searchGrave');
  searchGrave.addEventListener('input', (e) => {
    filterSearch(data, e.target.value);
  })

}
// #endregion

// #region Functions
function loadCemetery(map, search = null) {
  map.entities.clear();
  (search != null) && showLoading('Searching, please wait.')
  axios
    .post(`${server}/index.php`, {query: (search == null) ? '' : search})
    .then((response) => {
      // console.log(response.data.records)
      if (search != null) {
        swal.close()
      }
      // #region Records
      response.data.records.forEach((c) => {
        // #region Cemetery
        let polygon = new Microsoft.Maps.Polygon(
          JSON.parse(c.trace),
          styles.default
        );
        polygon.metadata = c //set metadata of trace
        Microsoft.Maps.Events.addHandler(polygon, "click", function (e) {
          showPanel(c)
          e.target.setOptions(styles.active);
          Microsoft.Maps.loadModule("Microsoft.Maps.SpatialMath", function () {
            map.setView({
              zoom: 17,
              center: Microsoft.Maps.SpatialMath.Geometry.centroid(e.target),
            })
            {
              (c.name && c.address) && infobox.setOptions({
              description: `
                <div style="width: 300px">
                  ${c.address && `<h1 class='fs-5 fw-bolder m-0 text-dark'>${c.name}</h1>`}
                  ${c.address && `
                    <span class='d-block text-truncate py-1 border-bottom'>
                      <i class='bi-geo-alt-fill'></i> ${c.address}
                    </span>`}
                  <span class='d-block'>${((c.description) ? c.description : 'No description available.')}</span>
                </div>`,
              location: Microsoft.Maps.SpatialMath.Geometry.centroid(e.target),
              visible: true
            });
            }
          })
        });
        Microsoft.Maps.Events.addHandler(polygon, "mouseover", function (e) {
          e.target.setOptions(styles.hover);
        });
        Microsoft.Maps.Events.addHandler(polygon, "mouseout", function (e) {
          e.target.setOptions(styles.default);
          infobox.setOptions({visible: false});
        });
        map.entities.push(polygon);
        // #endregion
        // #region Graves
        (search != null && c.graves.length == 0) && showMessage('warning', `No result found for ${search}.`)
        if (showGraves && c.graves.length > 0) {
          c.graves.forEach(g => {
            let name = (g.first_name != '')?(g.first_name + (g.midlle_name!=''?` ${(g.middle_name.length == 1)?g.middle_name[0]+'.':g.middle_name} `:` `) + g.last_name):'No name'
            let pin_location = JSON.parse(g.location)
            let pin = new Microsoft.Maps.Pushpin(pin_location, {
              icon: poi,
              anchor: new Microsoft.Maps.Point(12, 36),
              isMicroPoi: true,
            });
            Microsoft.Maps.Events.addHandler(pin, "click", function (e) {
              infobox.setOptions({
                description: `
                <div style="width: 300px">
                  <h1 class='fs-5 fw-bolder m-0 text-dark'>${name}</h1>
                  <div class="d-flex flex-column flex-fill">
                    <small class="text-muted"><i class="bi-calendar"></i> Born on: <span id="born_on">${g.born_on}</span></small>
                    <small class="text-muted"><i class="bi-calendar"></i> Died on: <span id="died_on">${g.died_on}</span></small>
                  </div>
                </div>`,
                location: e.target.getLocation(),
                visible: true,
                offset: new Microsoft.Maps.Point(0, 20)
              });
            });
            map.entities.push(pin);
          })
        }
        // #endregion
      });
      // #endregion
    })
    .catch((error) => {
      console.log(error);
    });
}
// #endregion

// #region Map
function getMap() {

  let center = new Microsoft.Maps.Location(7.7952876484067986, 122.60082189755387)

  map = new Microsoft.Maps.Map("#map", {
    credentials:
      "Av5AefOeIqwi0bkOehI7bdTzeD5kLNaU7BDoAaCnPkW0aOgWZFR-UOvgkuKUJ-GW",
    center: center,
    mapTypeId: Microsoft.Maps.MapTypeId.aerial,
    labelOverlay: Microsoft.Maps.LabelOverlay.hidden,
    zoom: 18,
    minZoom: 15,
    maxZoom: 20,
    disableStreetside: true,
    allowHidingLabelsOfRoad: true,
    enableHighDpi: true,
    willReadFrequently: true,
    showDashboard: false,
    showCopyright: false,
    showScalebar: false,
  });

  infobox = new Microsoft.Maps.Infobox(map.getCenter(), {
    maxHeight: 400,
    maxWidth: 400,
    showCloseButton: false,
    visible: false
  });
  
  infobox.setMap(map);

  loadCemetery(map);

  document.addEventListener("keydown", (e) => {
    if (
      (e.key == "z" && e.ctrlKey && isAdding) ||
      (e.key == "Delete" && e.ctrlKey && isAdding)
    ) {
      if (locations.length > 1) {
        locations.pop();
        new_polygon.setRings(locations);
        showMessage("success", "Trace point was deleted.");
      } else {
        showTooltip("warning", "Unable to remove points minimum of one.");
      }
    }
    if (e.key == "Delete" && e.ctrlKey && !isAdding) {
      locations = [];
      isAdding = false;
      isClicked = false;
      new_polygon.dispose()
      updateCommandButton();
      showMessage("success", "Map trace was deleted.");
      infobox.setOptions({ visible: false })
      loadCemetery(map)
    }
  });

  Microsoft.Maps.Events.addHandler(map, "click", function (e) {
    hidePanel()
    if (isAdding) {
      locations.push(e.location);
      if (!isClicked) {
        isClicked = true;
        new_polygon = new Microsoft.Maps.Polygon(locations, styles.new);
        map.entities.push(new_polygon);
        Microsoft.Maps.Events.addHandler(new_polygon, "dblclick", function (e) {
          e.target.setOptions(styles.hover);
          getSaveForm(new_polygon.getRings());
        });
        Microsoft.Maps.Events.addHandler(new_polygon, "mouseover", function (e) {
          e.target.setOptions(styles.unsave);
          if (locations.length > 0) {
            Microsoft.Maps.loadModule("Microsoft.Maps.SpatialMath", function () {
              infobox.setOptions({
                 description: `
                  <div style='width: 200px' class='text-center'>
                    <h1 class='fs-5 fw-bolder m-0 text-dark py-1 border-bottom'>Unsaved trace</h1>
                    <small>Double click this trace to save.</small>
                  </div>`,
                location: Microsoft.Maps.SpatialMath.Geometry.centroid(new_polygon.getRings()),
                visible: true,
              });
            });
          }
        });
        Microsoft.Maps.Events.addHandler(new_polygon, "mouseout", function (e) {
          e.target.setOptions(styles.new);
        });
      }
    }
  });
  Microsoft.Maps.Events.addHandler(map, "mousemove", function (e) {
    if (isAdding) {
      if (locations.length > 0) {
        locations.push(e.location);
        new_polygon.setRings(locations);
        locations.pop();
      }
    }
  });
  Microsoft.Maps.Events.addHandler(map, "mousewheel", function (e) {
    if (showGraves) {
      let pins = map.entities._primitives
      pins.forEach(p => {
        if (p.geometryType == 1) {
          if (map.getZoom() <= 16) {
            p.setOptions({ visible: false })
          } else {
            p.setOptions({ visible: true })
          }
        }
      })
    }
  });
}
// #endregion

// #region Alerts
function showLoading(message) {
  swal.fire({
    html: `
      <div class="d-flex flex-row align-items-center gap-2">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span>${message}</span>
      </div>
    `,
    width: '30vw',
    showConfirmButton: false,
    allowOutsideClick: false,
    customClass: {
      container: 'p-0',
      popup: 'p-0 m-0',
      htmlContainer: 'p-5 m-0',
    }
  });
}

function showMessage(icon, message) {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: false,
  });
  Toast.fire({
    icon: icon,
    title: message,
    width: '30vw'
  });
}

function showTooltip(icon, message) {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: false,
  });
  Toast.fire({
    icon: icon,
    title: message,
    width: '30vw'
  });
}

// #endregion

// #region Buttons

let pinButton = document.getElementById("pin");
pinButton.addEventListener("click", (e) => {
  if (showGraves) {
    showGraves = false
    pinButton.innerHTML = "<i class='bi-geo-alt-fill'></i> Show";
  } else {
    showGraves = true
    pinButton.innerHTML = "<i class='bi-geo-alt-fill'></i> Hide";
  }
  loadCemetery(map)
});

let searchButton = document.getElementById("searchButton");
let searchText = document.getElementById("searchText");
searchButton.addEventListener("click", (e) => {
  showGraves = true
  loadCemetery(map, searchText.value)
});

searchText.addEventListener("input", (e) => {
  if (e.target.value == '') {
    showGraves = true
    loadCemetery(map, searchText.value)
  }
});

// #endregion

window.onload = getMap;
